/* Base styles */
body {
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Wizard container */
.wizard {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Wizard header */
.wizard-header {
  margin-bottom: 30px;
}

.wizard-header h1 {
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 5px;
  color: #333;
}

.wizard-header h2 {
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 20px;
  color: #666;
}

/* Job description button */
.view-job-description {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-job-description:hover {
  background-color: #0056b3;
}

/* Error message */
.error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.button {
  background-color: #3ab6a1;

}

.button:hover {
  background-color: #fff;
  color: #3ab6a1;
}

/* add new to list */
.fade-in {
  animation: fadeIn 0.5s;
}

.fade-out {
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.modal-content {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
}

input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

button {
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  .no-border-end-mobile {
    border-right: none !important;
  }
}