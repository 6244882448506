/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #3ab6a1;
  color: #fff;
}

.navbar-logo img {
  height: 40px;
}

.navbar-login {
  display: flex;
  align-items: center;
}

.profile-section {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-picture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f4f4f9;
}

.dropdown-menu button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 10px;
}

.dropdown-menu button:hover {
  background-color: #f4f4f9;
}


.responsive-card-width {
  width: 75%;
}

@media (max-width: 768px) {
  .responsive-card-width {
    width: 95%;
  }
}