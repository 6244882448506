/* src/components/Footer/Footer.css */
.footer {
  background-color: #3ab6a1;
  color: #fff;
  padding: 10px 10%;
  text-align: center;
  width: 100%;
  font-size: x-small;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  flex: 1;
  min-width: 200px;
}

.contact-left,
.contact-center,
.contact-right {
  text-align: left;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info p strong {
  color: #ddd;
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
    padding: 10px 5%;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    text-align: center;
    margin-bottom: 10px;
  }
}