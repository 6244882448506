/* src/App.css */
html,
body {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Responsive styles */
@media (max-width: 600px) {
  .App {
    padding: 0 10px;
  }
}
.cursor-pointer{
  color: red;
  cursor: pointer;
}