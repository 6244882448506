.job-description {
  padding-left: 15%;
  padding-right: 15%;
  line-height: 1.6;
}

.job-description ul {
  list-style-type: disc;
  padding-left: 20px;
}

.apply-button {
  margin: 20px 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #555;
}

/* Responsive styles */
@media (max-width: 600px) {
  .job-description {
    padding: 10px;
  }

  .job-description ul {
    padding-left: 15px;
  }

  .apply-button {
    padding: 8px 16px;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.responsive-card-width {
  width: 75%;
}

@media (max-width: 768px) {
  .responsive-card-width {
    width: 95%;
  }
}